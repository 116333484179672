import {
  queryHotelOrderList,
  cancelOrderSubmit
} from "@/lib/data-service/hotel";
import scrollTop from "@/lib/common-service/scrollTop";
import { STATUS } from "@/lib/deprecated/utils/status";
const SUCCESS_CODE = STATUS.success;
export default {
  data() {
    return {
      formData: {
        pageSize: 10,
        currentPage: 1,
        createTime: ""
      },
      totalPage: 0,
      value1: "",
      tabelNav: [
        { text: "全部订单", statu: true, val: null },
        { text: "待支付", statu: false, val: 0 },
        // {text:'支付中',statu:false,val:1},
        { text: "已支付", statu: false, val: 2 },
        { text: "待确认", statu: false, val: 3 },
        { text: "已确认", statu: false, val: 4 },
        { text: "已拒单", statu: false, val: 5 }
        // {text:'已取消',statu:false,val:6},
        // {text:'取消中',statu:false,val:6},
        // {text:'取消失败',statu:false,val:8},
      ],
      tableData: [],
      img: `https://yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/default-v2/src/assets/hotel.jpg`
    };
  },
  created() {
    this.init();
  },
  methods: {
    init () {
      if (this.$route.query.orderStatus) {
        const orderStatus = Number(this.$route.query.orderStatus);
        this.formData.orderStatus = orderStatus;
        this.tabelNav.forEach((item) => {
          if (item.val === orderStatus) {
            item.statu = true;
          } else {
            item.statu = false;
          }
        });
      }
      this.getList();
    },

    //表格类型搜索
    getNavList(val, index) {
      let data = this.formData;
      this.tabelNav.map(item => {
        item.statu = false;
      });
      this.$nextTick(() => {
        this.tabelNav[index].statu = true;
      });
      data.orderStatus = val.val;
      this.formData.currentPage = 1;
      this.getList();
    },
    reset() {
      this.formData = {
        pageSize: 10,
        currentPage: 1
      };
    },
    //待支付
    goPay(val) {
      let routeUrl = this.$router.resolve({
        name: "distributor-hotel-front-hotel-pay",
        query: { orderNo: val.orderNo }
      });
      window.open(routeUrl.href, "_blank");
    },
    //表格选择
    handleSelectionChange(val) {
      console.log(val);
    },
    //分页
    handleCurrentChange(val) {
      this.formData.currentPage = val;
      this.getList();
    },
    //获取订单列表
    getList() {
      let data = this.formData;
      data.createTime = data.createTime
        ? this.$moment(data.createTime).format("YYYY-MM-DD")
        : "";
      queryHotelOrderList(data).then(res => {
        console.log(res);
        if (res.code === SUCCESS_CODE) {
          scrollTop(500);
          this.tableData = res.pageResult.pageData;
          this.totalPage = res.pageResult.totalCount;
        }
      });
    },
    //查看订单
    goInfo(val) {
      this.$router.push({
        path: "hotel-manage-order-info",
        query: { orderNo: val.orderNo }
      });
    },
    //取消订单
    cancelOrder(val) {
      // console.log(val)
      let data = {
        orderNo: val.orderNo
      };
      this.$prompt("请输入取消原因：", "取消订单", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(({ value }) => {
          if (!value || /(^\s+)|(\s+$)/g.test(value))
            return this.$message({
              type: "warning",
              message: "请填写取消原因"
            });
          data.cancelRemark = value;
          cancelOrderSubmit(data).then(res => {
            if (res.code === SUCCESS_CODE) {
              this.$message({ type: "success", message: "取消订单成功" });
              this.getList();
            }
          });
        })
        .catch(() => {});
    }
  }
};